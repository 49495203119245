import React, { FC } from 'react';
import ProfileCard from '../../../../components/profileCard';
import { StockProfiles } from '../../../../library/staticData';
import { ProfileCardContainer } from './profile.styled';
import FindrText from '../../../../assets/images/FindrTextGold.png';
import { BackButtonContainer } from '../components.styled';
import BackButton from '../../../../assets/images/backButton.png';

interface Props {
  backClicked: () => void;
  userId: string;
}
const Profile: FC<Props> = ({ backClicked, userId }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        position: 'relative'
      }}>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      <img
        src={FindrText}
        alt='logo'
        height='40'
        width='120'
        style={{ margin: 8, objectFit: 'contain' }}
      />
      <ProfileCardContainer>
        {StockProfiles.map((p, index) => {
          return <ProfileCard profile={p} />;
        })}
      </ProfileCardContainer>
    </div>
  );
};

export default Profile;
