import React, { FC } from 'react';
import { BackButtonContainer } from '../components.styled';
import BackButton from '../../../../assets/images/backButton.png';
import KneeVideo from '../../../../assets/images/TKRsmall.mp4';
import { HeaderLogoContainer } from '../../Home.styled';
import FindrText from '../../../../assets/images/FindrTextGold.png';

interface VideoProps {
  backClicked: () => void;
  userId: string;
}
const VideoScreen: FC<VideoProps> = ({ backClicked, userId }) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'relative'
      }}>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      <div
        style={{
          position: 'relative',
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <HeaderLogoContainer src={FindrText} />
      </div>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          marginTop: -80
        }}>
        <img
          alt='kneeVid'
          src={KneeVideo}
          style={{
            width: '100%',
            height: 200,
            objectPosition: 'center',
            zIndex: 2
          }}
        />
      </div> */}
      <video
        src={KneeVideo}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          objectPosition: 'center',
          zIndex: 2
        }}
        playsInline
        autoPlay={true}
        loop
        muted
        controls
      />
    </div>
  );
};

export default VideoScreen;
