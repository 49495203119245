import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import Bird from '../../assets/images/FindrBird.png';
import Verified from '../../assets/images/findrVerified.jpeg';
import { BackButtonContainer } from '../../Screens/Home/components/components.styled';
import { GoldButton } from '../../Screens/Home/Home.styled';
import BackButton from '../../assets/images/backButton.png';
import { CheckBox } from './waiting.styled';

interface Props {
  nextClicked: () => void;
  backClicked: () => void;
  userId: string;
}
const Waiting: FC<Props> = ({ nextClicked, backClicked, userId }) => {
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  useEffect(() => {
    let timer: any;
    if (!showQuestions) {
      timer = setTimeout(() => {
        nextClicked();
      }, 5000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQuestions]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative'
      }}>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      {!showQuestions ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            height: '90%'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <motion.div
              animate={{
                scale: [0.9, 1.2],
                opacity: [1, 0.4],
                transition: {
                  duration: 1.8,
                  bounce: 0.25,
                  repeat: Infinity,
                  repeatType: 'reverse'
                }
              }}
              style={{ marginBottom: 20 }}>
              <img src={Bird} alt='bird' width={100} />
            </motion.div>
            <text style={{ color: '#fff', fontSize: 17, marginBottom: 20 }}>
              Loading your{' '}
              <text style={{ fontWeight: 'bold' }}>FINDR Verified</text> Doctors
            </text>
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 8
                }}>
                <CheckBox>✓</CheckBox>
                <text style={{ color: '#fff', fontSize: 16 }}>
                  Peer Reviewed
                </text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 8
                }}>
                <CheckBox>✓</CheckBox>
                <text style={{ color: '#fff', fontSize: 16 }}>
                  100% Data Driven and Unbiased
                </text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CheckBox>✓</CheckBox>
                <text style={{ color: '#fff', fontSize: 16 }}>
                  Expertise Matched to Your Needs
                </text>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          <text style={{ color: '#fff', fontSize: 12, marginBottom: 20 }}>
            Question 1
          </text>
          <text style={{ color: '#fff', fontSize: 12 }}>Question 2</text>
          <div
            style={{
              position: 'absolute',
              bottom: 40,
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <GoldButton onClick={nextClicked}>
              try <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
              <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                DR
              </text>
            </GoldButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Waiting;
