export const colors = {
  white: '#fff',
  black: '#000',
  gray: '#ccc',
  gray2: '#eee',
  gray3: '#f1f1f1',
  halfGray: '#0faafa50',
  gold: '#D5AD57',
  blue1: '#202C44',
  blue2: '#616772',

  triBlueGrad: '#141E2C,#273043,#141E2C',
  blueGrad: '#273043, #141E2C',
  yellowGrad: '#D5AD57,#F4D28F',
  goldGrad: '#a95, #f2f2f2 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, #a95',
  whiteSmoke: '#f5f5f5',
  fadedLemon: '#F1EA0640',
  lightBlue: '#27304b'
};
