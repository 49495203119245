import styled from "styled-components"

const Container = styled.div`
    width: 100vw;
    height: 100%;
    padding-bottom: 40px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #0f0c29, #302b63, #24243e);
`

const InnerContainer = styled.div`
    width: 335px;
    height: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`

const TableRow  = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 40% 40% 20%;
`

const HeaderCell = styled.div`
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    color: #000;
    background-color: #aaa;
`

const HeaderNumberCell = styled.div`
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    color: #000;
    background-color: #aaa;
`

const TableTextCell = styled.div`
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    align-items: center;
    border: 0.5px solid #fff;
    color: #fff;
`

const TableNumberCell = styled.div`
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    align-items: center;
    border: 1px solid #fff;
    color: #fff;
`

export { Container, InnerContainer, TableRow, HeaderCell, TableTextCell, TableNumberCell, HeaderNumberCell }