import styled from "styled-components"
import { colors } from '../../theme'

const CreateContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, ${colors.triBlueGrad});
    overflow: scroll;
`

const CreateInner = styled.div`
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 80px;
`

const CreateButton = styled.button`
    padding: 6px 40px;
    background: linear-gradient(${colors.yellowGrad});
    outline: none;
    border: none;
    border-radius: 4px;
    margin-top: 32px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

const CopyButton = styled.button<{ copied: boolean}>`
    color: ${({ copied }) => copied ? colors.black : colors.white};
    margin-left: 16px;
    background-color: ${({ copied}) => copied ? colors.white : 'transparent'};
    border: 2px solid ${colors.white};
    width: 80px;
    height: 25px;
    border-radius: 15px;
    cursor: pointer;

    &:hover {
        ${({ copied }) => !copied && 'opacity: 0.8;'};
    }
`

const InfoText = styled.div`
    font-size: 14px;
    color: ${colors.gray};
    margin-top: 16px;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const CodeText = styled.text`
    margin: 10px;
    color: ${colors.white};
    font-size: 28px;
`

const CreateTitle = styled.text`
    margin: 16px;
    color: ${colors.white};
    font-weight: 800;
`
const ExtraText = styled.text`
    font-size: 10px;
    color: ${colors.gray};
    margin-bottom: 4px;
`
const CreateInput = styled.input`
    margin-bottom: 32px;
    width: 98%;
    height: 30px;
    background-color: ${colors.lightBlue};
    border: none;
    box-shadow: 2px 4px 8px #F1EA0620;
    padding: 4px;
    color: ${colors.white};
`

export { 
    CreateButton,
    CopyButton,
    CreateContainer,
    CreateInner,
    InfoText,
    InfoContainer,
    CodeText,
    CreateTitle,
    ExtraText,
    CreateInput
}