import { ref, set, get, child } from 'firebase/database';
import { database } from '../firebaseApp';
import dayjs from 'dayjs';

export const addAccessCode = (code: string, email: string, phone: string) => {
  set(ref(database, `accessCodes/${code}`), { email: email, phone: phone });
};

export const addUidToDatabase = (
  uid: string,
  address: string,
  phone: string
) => {
  console.log(uid, address, phone);
  console.log('---ADD UID TO DB');
  set(ref(database, 'users/' + uid), {
    email: address,
    identifier: phone
  });
};

export const checkCode = async (code: string) => {
  const dbRef = ref(database);
  const user = await get(child(dbRef, `accessCodes/${code}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log('No data available');
        return undefined;
      }
    })
    .catch((error) => {
      console.error(error);
      return undefined;
    });
  return user;
};

export const updateCodeInfo = (code: string, email: string, phone: string) => {
  set(ref(database, `accessCodes/${code}`), { email: email, phone: phone });
};

export const getCount = async () => {
  const dbRef = ref(database);
  const user = await get(child(dbRef, `users`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.size;
      } else {
        console.log('No data available');
        return 0;
      }
    })
    .catch((error) => {
      console.error(error);
      return 0;
    });
  return user;
};

export const getStats = async () => {
  const dbRef = ref(database);
  let tempArray: any[] = [];
  await get(child(dbRef, `stats`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((newDoc: any) => {
          tempArray.push({
            item: newDoc.key,
            description: newDoc.val().description,
            count: newDoc.size - 1,
            id: newDoc.val().id || 100
          });
        });
      } else {
        console.log('No data available');
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return tempArray;
};

export const addStat = (statType: string, code: string) => {
  set(
    ref(
      database,
      `stats/${statType}/${dayjs(Date.now()).format('MMM DD YYYY hh:mm:ss a')}`
    ),
    code
  );
};

export const getUserStats = async (identifierIn: string) => {
  const dbRef = ref(database);
  let tempArray: any[] = [];
  let uid = '';
  let info: { email: string | null; phone: string } = {
    email: null,
    phone: ''
  };

  if (!identifierIn.includes('.')) {
    await get(child(dbRef, `accessCodes/${identifierIn}`)).then((snapshot) => {
      if (snapshot.exists()) {
        info = snapshot.val();
      } else {
        console.log('No data available');
      }
    });
  }
  console.log(info);
  console.log('-----INFO----');
  await get(child(dbRef, `users`)).then((snapshot) => {
    if (snapshot.exists()) {
      snapshot.forEach((newDoc: any) => {
        if (info.email) {
          if (
            newDoc.val().email === info.email ||
            newDoc.val().identifier === info.phone
          ) {
            //const uid = newDoc.key;
            console.log(newDoc.key);
            uid = newDoc.key;
          }
        } else {
          if (
            newDoc.val().email === identifierIn ||
            newDoc.val().identifier === identifierIn
          ) {
            //const uid = newDoc.key;
            console.log(newDoc.key);
            uid = newDoc.key;
          }
        }
      });
    } else {
      console.log('No data available');
    }
  });
  await get(child(dbRef, `stats`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((newDoc: any) => {
          let count = 0;
          newDoc.forEach((doc: any) => {
            if (doc.val() === uid) {
              count += 1;
            }
          });
          tempArray.push({
            item: newDoc.key,
            description: newDoc.val().description,
            count: count,
            id: newDoc.val().id || 100
          });
        });
      } else {
        console.log('No data available');
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return { data: tempArray, id: info.email };
};
