import styled from "styled-components/macro";


const BottomBanner = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 90px;
    display: flex;
    flex-direction: row;
    background: linear-gradient(#273043, #273043,#A5A5A890);
    justify-content: space-evenly;
`

const GradientText = styled.text`
    background: linear-gradient(top, #a95, #f2f2f2 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, #a95);
    background: -webkit-linear-gradient(top, #a95, #fea 25%, #ffffff 38%, #dc8  63%, #fea 87%, #a95);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    margin-bottom: 8px;
`

const TitleText = styled.text`
    font-size: 18px;
    margin-bottom: 8px;
    color: white;
`

const BackButtonContainer = styled.img`
    width: 20px;
    height: 25px;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 40;

    &:hover {
        opacity: 0.8;
    }
`

const BackButtonWithBack = styled.img`
    width: 20px;
    height: 25px;
    position: absolute;
    background-color: #141E2C;
    height: 20px;
    width: 20px;
    border-bottom-right-radius: 50%;
    padding: 10px;
    top: 0px;
    left: 0px;
    cursor: pointer;
    z-index: 40;

    &:hover {
        opacity: 0.9;
    }
`

export { TitleText, BottomBanner, GradientText, BackButtonContainer, BackButtonWithBack };