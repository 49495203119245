import styled from "styled-components"
import { colors } from "../../theme"

const TopNotch = styled.div`
    width: 50%;
    background-color: ${colors.black};
    min-height: 40px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
`
const Speaker = styled.div`
    height: 5px;
    width: 40%;
    background-color: #333333;
    border-radius: 5px;
`

const Dot = styled.div`
    height: 14px;
    width: 14px;
    background-color: #3B444B;
    border-radius: 8px;
`
export { TopNotch, Speaker, Dot }