import React, { FC, useEffect, useState } from 'react';
import Accordion from '../../../../components/accordion';
import { filterOptions } from '../../../../library/staticData';
import Bird from '../../../../assets/images/FindrBird.png';
import { BackButtonContainer, GradientText } from '../components.styled';
import BackButton from '../../../../assets/images/backButton.png';
import { GoldButton } from '../../Home.styled';

interface Props {
  backClicked: () => void;
  nextClicked: (options: any[]) => void;
  userId: string;
  clear: () => void;
}
const Filters: FC<Props> = ({ nextClicked, backClicked, userId, clear }) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    clear();
  }, []);

  console.log(options);
  console.log('----OPTIONS-----');
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 40,
        alignItems: 'center',
        position: 'relative'
      }}>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      <img src={Bird} style={{ width: 90, height: 50, margin: 8 }} />
      <div
        style={{
          width: '70%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: 12,
          alignItems: 'center'
        }}>
        <GradientText>Choose 3 Key Qualifiers</GradientText>
      </div>
      <div
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          width: '90%'
        }}>
        {filterOptions.map((option) => {
          return (
            <Accordion
              title={option.title}
              options={option.options}
              type={option.type}
              user={userId}
              setSelected={(selected) => {
                setOptions([...options, selected]);
              }}
              removeSelected={(selected) => {
                setOptions(options.filter((o) => o.value !== selected.value));
              }}
            />
          );
        })}
      </div>
      {/* <GoldButton onClick={nextClicked}>
        <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
          continue
        </text>
      </GoldButton> */}
      <GoldButton
        onClick={() => {
          nextClicked(options);
        }}>
        try <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
        <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>DR</text>
      </GoldButton>
    </div>
  );
};

export default Filters;
