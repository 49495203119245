import styled from  'styled-components/macro'
import { colors } from '../../theme'

const Container = styled.div<{mobile: boolean}>`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ mobile }) => mobile ? 'linear-gradient(#141E2C,#273043)' : '#1AA7EC'};
    overflow: auto;
`

const MobileContainer = styled.div`
    width: 335px;
    height: 620px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(#141E2C,#273043);
    border-radius: 50px;
    border: 16px solid #000;
    flex-direction: column;
    overflow: hidden;
`

const NonMobileContainer = styled.div`
    flex: 1;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(#141E2C,#273043);
    flex-direction: column;
    overflow: hidden;
    margin-top: -100px;
`

const EnterButton = styled.button`
    width: 50%;
    height: 35px;
    border-radius: 4px;
    background:linear-gradient(#D5AD57,#F4D28F);
    border: none;
    font-size: 16px;
    font-weight: bold;

    &:hover:enabled {
        cursor: pointer;
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
    }
`

const GoldButton = styled.button<{ rounded?: boolean; small?: boolean }>`
    font-family: Chancery;
    width: ${({ small }) => small ? '80%' : '60%'};
    height: ${({ small}) => small ? 20 : 40}px;
    color: ${({ small}) => small ? '#000' : '#D5AD57'};
    border-radius: ${({ rounded }) => rounded ? 20 : 5}px;
    padding: ${({ small}) => small ? '0px' : '10px 25px'};
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-shadow: inset 0px 1px 0px ${colors.white},0px 1px 3px rgba(0,0,0,0.3);
    outline: none;
    border: 1px solid #ba6;
    margin-top: 10px;
    justify-content: center;

    background: linear-gradient(top, #a95, #f2f2f2 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, #a95);
    background: -webkit-linear-gradient(top, #a95, #fea 25%, #ffffff 38%, #dc8  63%, #fea 87%, #a95);

  &:active{
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  &:hover:enabled {
    opacity: 0.95;
    color: #00000050;
  }

  &:disabled {
    opacity: 0.5;
}
`

const HeaderLogoContainer = styled.img`
  margin: 8px;
  object-fit: contain;
  height: 40px;
  width: 120px;
`

export { 
  Container,
  MobileContainer,
  EnterButton,
  GoldButton,
  HeaderLogoContainer,
  NonMobileContainer
}