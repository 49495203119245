import { FC, useEffect, useState } from 'react';
import { addStat } from '../../services/firebase';
import { colors } from '../../theme';
import { Option, OptionBody, OptionInput, RowHeader } from './accordion.styled';

interface AccordionProps {
  title: string;
  options: string[];
  type: 'boolean' | 'select' | 'input';
  user: string;
  setSelected: (info: any) => void;
  removeSelected: (info: any) => void;
}
const Accordion: FC<AccordionProps> = ({
  title,
  options,
  type,
  user,
  setSelected,
  removeSelected
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [facInput, setFacInput] = useState<string>('');
  const [storeInput, setStoreInput] = useState<string>('');

  const renderInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <OptionInput
          placeholder='Enter a facility'
          value={facInput}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setOptionsSelected(
                optionsSelected.filter((o) => o !== storeInput)
              );
              removeSelected({ title: title, value: storeInput });
              setStoreInput('');
            }
            setFacInput(e.target.value);
          }}
        />
        <button
          style={{ marginLeft: 8, height: 30, width: 30 }}
          onClick={() => {
            setOptionsSelected([...optionsSelected, facInput]);
            setSelected({ title: title, value: facInput });
            setOpen(false);
            setStoreInput(facInput);
          }}>
          +
        </button>
      </div>
    );
  };

  return (
    <div style={{ marginBottom: 12 }}>
      <RowHeader
        active={optionsSelected.length > 0}
        animate={{
          borderBottomLeftRadius: open ? '0px' : '8px',
          borderBottomRightRadius: open ? '0px' : '8px',
          borderBottom: open ? '0px' : `1px solid ${colors.gold}`
        }}
        transition={{
          duration: open ? 0.05 : 0.3
        }}
        onClick={() => {
          if (type === 'boolean') {
            if (optionsSelected.includes('pediatric')) {
              setOptionsSelected(
                optionsSelected.filter((o) => o !== 'pediatric')
              );
            } else {
              addStat(`Filter option: ${title}`, user || 'Anonymous');
              setOptionsSelected([...optionsSelected, 'pediatric']);
            }
          } else {
            setOpen(!open);
          }
        }}>
        {title}
      </RowHeader>
      <OptionBody
        initial='closed'
        animate={open ? 'open' : 'closed'}
        exit='closed'
        variants={{
          open: {
            height: 'auto',
            padding: 8
          },
          closed: {
            height: 0,
            padding: 0
          }
        }}
        transition={{ duration: open ? 0.5 : 0.2 }}>
        {options.map((opt) => {
          return type === 'input' ? (
            renderInput()
          ) : (
            <Option
              key={opt}
              active={optionsSelected.includes(opt)}
              onClick={() => {
                if (optionsSelected.includes(opt)) {
                  setOptionsSelected(optionsSelected.filter((o) => o !== opt));
                  removeSelected({ title: title, value: opt });
                } else {
                  addStat(`Filter option: ${opt}`, user || 'Anonymous');
                  setOptionsSelected([...optionsSelected, opt]);
                  setSelected({ title: title, value: opt });
                }
              }}>
              {opt}
            </Option>
          );
        })}
      </OptionBody>
    </div>
  );
};

export default Accordion;
