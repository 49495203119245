import styled from "styled-components"
import { colors } from "../../../../theme"

const Container = styled.div`
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
`

const HeaderContainer = styled.div`
    height: 33%;
    flex: 1;
    border-bottom: solid 0.5px #F4D28F50;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #141E2C, #69696C20);
    box-shadow: 0px 2px 6px #F1EA0610;
    overflow: hidden;
`

const BottomContainer = styled.div`
    height: 58%;
    padding: 8px 6px 22px 6px;
`

const BottomInner = styled.div<{mobile: boolean}>`
    background-color: #2D66F115;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: ${({mobile}) => mobile ? '4px 16px' : '4px'};
    flex-direction: column;
    justify-content: space-evenly;
`

const DescriptionText = styled.text`
    font-family: sans-serif;
    color: ${colors.white};
    text-align: center;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 20px;
    font-size: 13px;
`


export { Container, HeaderContainer, BottomContainer, BottomInner, DescriptionText }