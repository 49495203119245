import { ProfileInfo } from '../types/types';
import drOne from '../assets/images/dr1.jpg';
import drOneHead from '../assets/images/drOneHead.jpg';
import drTwoHead from '../assets/images/drTwoHead.jpeg';
import drThreeHead from '../assets/images/drThreeHead.jpeg';
import drFourHead from '../assets/images/drFourHead.jpg';
import drFiveHead from '../assets/images/drFiveHead.jpg';
import drTwo from '../assets/images/dr2.jpg';
import drThree from '../assets/images/dr3.jpg';
import drFour from '../assets/images/dr4.jpg';
import drFive from '../assets/images/dr5.jpg';
import drSeven from '../assets/images/drSeven.jpg';
import drSevenHead from '../assets/images/drSevenHead.jpg';
import Bird from '../assets/images/FindrBird.png';

type option = {
  title: string;
  options: string[];
  type: 'boolean' | 'select' | 'input';
};
export const filterOptions: option[] = [
  {
    title: 'Insurance Provider',
    options: [
      'United Health Care',
      'Cigna',
      'Kaiser Permanente',
      'Anthem',
      'Blue Cross Blue Shield',
      'Aetna',
      'Other'
    ],
    type: 'select'
  },
  {
    title: 'Proximity',
    options: ['5 miles', '15 miles', '30 miles'],
    type: 'select'
  },
  {
    title: 'Pediatric',
    options: [],
    type: 'boolean'
  },
  {
    title: 'Multilingual',
    options: ['Chinese', 'Spanish', 'Tagalog', 'Vietnamese', 'Other'],
    type: 'select'
  },
  {
    title: 'Facility',
    options: ['Input'],
    type: 'input'
  },
  {
    title: 'Emergency',
    options: [],
    type: 'boolean'
  }
];

export const Prices = [
  'less than $5',
  '$5 - $10',
  '$10 - $50',
  '$50 - $100',
  '$100 - $150',
  '$150 - $200',
  'more than $200',
  'not sure'
];

export type Doctor = {
  name: string;
  headShot: string;
  photo: string;
  photoUrl?: string;
  address: string;
  rating: any[];
  phone: string;
};

export const FirstDoctor: Doctor = {
  name: 'Dr James Thomas',
  headShot: drOneHead,
  photo: drOne,
  address: '1234 Knob Hill, Yourtown, USA',
  rating: [Bird, Bird, Bird, Bird],
  phone: '555-555-5555'
};

export const Doctors: Doctor[] = [
  {
    name: 'Dr Ava Daniels',
    headShot: drTwoHead,
    photo: drTwo,
    photoUrl: '../../../../assets/images/dr2.jpg',
    address: '224 Park Ave, Yourtown, USA',
    rating: [Bird, Bird, Bird, Bird],
    phone: '555-555-5555'
  },
  {
    name: 'Dr Allen Moore',
    headShot: drFourHead,
    photo: drFourHead,
    address: '572 W 3rd St, Yourtown, USA',
    rating: [Bird, Bird, Bird],
    phone: '555-555-5555'
  },
  {
    name: 'Dr Joshua Patel',
    headShot: drFiveHead,
    photo: drFive,
    address: '920 Sunset Blvd, Yourtown, USA',
    rating: [Bird, Bird, Bird],
    phone: '555-555-5555'
  },
  {
    name: 'Dr Emma Michaels',
    headShot: drThreeHead,
    photo: drThree,
    address: '437 Beale St, Yourtown, USA',
    rating: [Bird, Bird],
    phone: '555-555-5555'
  },
  {
    name: 'Dr Andrew Chen',
    headShot: drSevenHead,
    photo: drSeven,
    address: '675 Main St, Yourtown, USA',
    rating: [Bird, Bird],
    phone: '555-555-5555'
  }
];

export const StockProfiles: ProfileInfo[] = [
  {
    name: 'Mom',
    avatar: '../../../../assets/images/DrOne.png'
  }
];
