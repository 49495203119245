import styled from "styled-components"

const ProfileCardContainer = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-x: scroll;
    margin-top: 20px;
    margin-left: 20px;
    ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }
      
    /* Track 
    ::-webkit-scrollbar-track {
        width: 200px;
        height: 30px;
        background: #979797;
        margin-right: 20px;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom, #D7AF5A, #EDD893);
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    } /*
`

export { ProfileCardContainer }