import Config from './config/ProdConfig';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';

import { getAuth } from 'firebase/auth'
import { getDatabase } from "firebase/database"
import { getAnalytics } from "firebase/analytics";

let app: FirebaseApp | undefined

if (!getApps().length) {
  app = initializeApp({
    apiKey: Config.FIREBASE.apiKey,
    authDomain: Config.FIREBASE.authDomain,
    databaseURL: Config.FIREBASE.databaseURL,
    projectId: Config.FIREBASE.projectId,
    storageBucket: Config.FIREBASE.storageBucket,
    messagingSenderId: Config.FIREBASE.messagingSenderId,
    appId: Config.FIREBASE.appId,
    measurementId: Config.FIREBASE.measurementId
  })
}

export const auth = getAuth(app)
export const database = getDatabase(app)
export const analytics = getAnalytics() 