import { useEffect, useState } from 'react';
import { GoldButton } from '../Home/Home.styled';
import Findr from '../../assets/images/FindrGoldBird.png';
import { generateCode } from '../../services/utils';
import { addAccessCode } from '../../services/firebase';
import {
  CodeText,
  CopyButton,
  CreateContainer,
  CreateInner,
  CreateInput,
  CreateTitle,
  ExtraText,
  InfoContainer,
  InfoText
} from './CreateCode.styled';
import { LargeLogo } from '../Home/components/login/login.styled';

const CreateCode = () => {
  const [code, setCode] = useState<string | undefined>(undefined);
  const [emailIn, setEmailIn] = useState<string | undefined>(undefined);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  const renderDetails = () => {
    return code ? (
      <>
        <InfoText>new code:</InfoText>
        <InfoContainer>
          <CodeText>{code}</CodeText>
          <CopyButton
            copied={copied}
            onClick={() => {
              if (!copied) {
                setCopied(true);
                navigator.clipboard.writeText(
                  `${window.location.origin}?code=${code}`
                );
              }
            }}>
            {copied ? 'copied' : 'copy code'}
          </CopyButton>
        </InfoContainer>
        <GoldButton
          onClick={() => {
            setCode(undefined);
            setEmailIn(undefined);
          }}>
          create another code
        </GoldButton>
      </>
    ) : (
      <>
        <CreateTitle>Create a New Access Code</CreateTitle>
        <ExtraText>
          Enter an email address if you know the email address of the user who
          will use this access code. (NOT required)
        </ExtraText>
        <CreateInput
          value={emailIn}
          onChange={(e) => {
            setEmailIn(e.target.value);
          }}
          placeholder='Users identifier (email, name, or phone number)'
        />
        <GoldButton
          onClick={() => {
            const codeGenerated = generateCode();
            setCode(codeGenerated);
            addAccessCode(codeGenerated, emailIn || 'empty', 'empty');
          }}>
          Create Access Code
        </GoldButton>
      </>
    );
  };

  return (
    <CreateContainer>
      <CreateInner>
        <LargeLogo src={Findr} />
        {renderDetails()}
      </CreateInner>
    </CreateContainer>
  );
};

export default CreateCode;
