import styled from "styled-components"
import { colors } from "../../../../theme"

const LoginContainer = styled.div`
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
`

const LargeLogo = styled.img`
    width: 120px;
    object-fit: contain;
`

const AccessInput = styled.input<{access?: boolean}>`
    width: 80%;
    height: 30px;
    margin-bottom: ${({ access }) => !access ? 4 : 40}px;
    background: none;
    border: solid 1px ${colors.whiteSmoke};
    padding: 2px 8px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px ${colors.fadedLemon};
    outline: none;
    color: ${colors.white};
    cursor: pointer;
`

const ErrorMsg = styled.div`
    margin-bottom: 36px;
    width: 80%;
    justify-content: flex-start;
    font-size: 10px;
    color: white;
    background: linear-gradient(top, #a95, #f2f2f2 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, #a95);
    background: -webkit-linear-gradient(top, #a95, #fea 25%, #ffffff 38%, #dc8  63%, #fea 87%, #a95);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;

    outline: none;
`

export { LoginContainer, LargeLogo, AccessInput, ErrorMsg }