import { FC } from 'react';
import {
  BackButtonContainer,
  GradientText,
  TitleText
} from '../components.styled';
import Knee from '../../../../assets/images/kneeJointFull.png';
import BackButton from '../../../../assets/images/backButton.png';
import {
  BottomContainer,
  BottomInner,
  Container,
  DescriptionText,
  HeaderContainer
} from './bodyPart.styled';
import Slider from '../../../../components/slider';
import { GoldButton } from '../../Home.styled';
import BodyPartKneeVid from '../../../../assets/images/bodyPartKneeVid.mp4';
import { isMobileOnly, isTablet } from 'react-device-detect';

interface Props {
  backClicked: () => void;
  nextClicked: () => void;
  toVideo: () => void;
  userId: string;
}
const BodyPart: FC<Props> = ({ backClicked, nextClicked, toVideo, userId }) => {
  return (
    <Container>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      <HeaderContainer>
        {/* <img
          src={Knee}
          alt='knee'
          height='100%'
          style={{ objectFit: 'contain' }}
        /> */}
        <video
          src={BodyPartKneeVid}
          style={{
            width: '100%',
            height: '100%',
            objectPosition: 'center',
            objectFit: 'contain',
            zIndex: 2
          }}
          playsInline
          autoPlay={true}
          loop
          muted
        />
      </HeaderContainer>
      <BottomContainer>
        <BottomInner mobile={isMobileOnly && !isTablet}>
          <GradientText>KNOW YOUR OPTIONS</GradientText>
          <DescriptionText>
            The <text style={{ fontStyle: 'italic' }}>options</text> below have
            been selected based on{' '}
            <text style={{ fontStyle: 'italic' }}>your health profile</text> and
            <text style={{ fontStyle: 'italic' }}>
              {' '}
              successful patient outcomes
            </text>
            .
          </DescriptionText>
          <Slider toVideo={toVideo} />
          {/* <GoldButton onClick={nextClicked}>
            <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
            <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              DR
            </text>
          </GoldButton> */}
          <GoldButton onClick={nextClicked}>
            try <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
            <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              DR
            </text>
          </GoldButton>
        </BottomInner>
      </BottomContainer>
    </Container>
  );
};

export default BodyPart;
