import { FC, useState } from 'react';
import { checkCode } from '../../../../services/firebase';
import { GoldButton } from '../../Home.styled';
import FindrBird from '../../../../assets/images/FindrBird.png';
import FindrText from '../../../../assets/images/FindrTextGold.png';
import {
  AccessInput,
  ErrorMsg,
  LargeLogo,
  LoginContainer
} from './login.styled';
import { newLogin } from '../../../../services/utils';
import { motion } from 'framer-motion';

interface LoginProps {
  userId: string;
  setUserInfo: (user: string | undefined) => void;
}
const Login: FC<LoginProps> = ({ userId, setUserInfo }) => {
  const [access, setAccess] = useState<boolean>(true);
  const param = new URLSearchParams(window.location.search);
  const codeParam = param.get('code');
  const [code, setCode] = useState<string | undefined>(
    codeParam ? codeParam : undefined
  );
  const [inPlace, setInPlace] = useState<boolean>(true);

  // useEffect(() => {
  //   const autoSignIn = async () => {
  //     if (code) {
  //       const user = await checkCode(code);
  //       if (!user) {
  //         setAccess(false);
  //       } else {
  //         const userId = await newLogin(user, code);
  //         setUserInfo(userId);
  //       }
  //     }
  //   };
  //   autoSignIn();
  // }, []);

  return (
    <LoginContainer>
      <LargeLogo src={FindrBird} alt='Findr Logo' />
      <motion.div
        initial={{ y: 600 }}
        animate={{ y: 8 }}
        transition={{ duration: 2 }}>
        <LargeLogo src={FindrText} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ delay: 2.2, duration: 0.8 }}
        animate={{ opacity: 1 }}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          width: '90%',
          marginTop: 40
        }}>
        <AccessInput
          access={access}
          placeholder='Enter your access code'
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            if (e.target.value.length === 0) {
              setAccess(true);
            }
          }}
        />
        {!access && <ErrorMsg>Invalid access code</ErrorMsg>}
        <GoldButton
          disabled={!code}
          onClick={async () => {
            if (code) {
              const user = await checkCode(code);

              console.log(user);
              console.log(user, '---------USER');

              if (!user) {
                setAccess(false);
              } else {
                const userId = await newLogin(user.email, code, user.phone);
                console.log(userId, '-------USERID');
                setUserInfo(userId);
              }
            }
          }}>
          try <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
          <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
            DR
          </text>
        </GoldButton>
      </motion.div>
    </LoginContainer>
  );
};

export default Login;
