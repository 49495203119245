import { FC, ReactElement } from 'react';
import {
  DetailContainer,
  RowContainer,
  TitleContainer,
  RowText,
  IconContainer
} from './inforRow.styled';

interface Props {
  title?: string;
  detail: string;
  icon?: ReactElement;
}
const InfoRow: FC<Props> = ({ title, detail, icon }) => {
  return (
    <RowContainer>
      {icon && <IconContainer>{icon}</IconContainer>}
      {title && (
        <TitleContainer>
          <RowText bold>{title}:</RowText>
        </TitleContainer>
      )}
      <DetailContainer fullWidth={!title}>
        <RowText bold={false}>{detail}</RowText>
      </DetailContainer>
    </RowContainer>
  );
};

export default InfoRow;
