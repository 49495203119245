import React, { FC } from 'react';
import { ProfileInfo } from '../../types/types';
import ProfileImg from '../../assets/images/OvalTwo.png';
import ProfileImgTwo from '../../assets/images/momZoomed.jpeg';

interface Props {
  profile: ProfileInfo;
}

const profileOptions: string[] = [
  'Health Profile',
  'Contact Care',
  'Community',
  'Reminders',
  'Favorites'
];

const ProfileCard: FC<Props> = ({ profile }) => {
  return (
    <div
      style={{
        minWidth: 260,
        height: '94%',
        border: '2px solid #D7AF5A',
        borderRadius: 4,
        background: 'linear-gradient(to bottom, #F4D28F20, #D5AD5720)',
        marginRight: 16,
        padding: 12
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}>
        <div
          style={{
            height: 180,
            width: 150,
            border: '1px Solid #D7AF5A',
            borderRadius: '50%',
            overflow: 'hidden',
            marginTop: 8,
            marginBottom: 8
          }}>
          <img
            src={ProfileImgTwo}
            alt='profilePhoto'
            height={'100%'}
            width='100%'
            style={{ objectFit: 'cover' }}
          />
        </div>
        <text
          style={{
            color: '#fff',
            fontSize: 18,
            fontWeight: '800',
            marginBottom: 8
          }}>
          {profile.name}
        </text>
        <div
          style={{
            width: '80%',
            height: 5,
            backgroundColor: '#EDD89340',
            borderRadius: 10
          }}
        />
        {profileOptions.map((option, index) => {
          return (
            <div
              style={{
                margin: 8,
                background: 'linear-gradient(#2D4057, #141E2C)',
                height: 50,
                width: '80%',
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                fontSize: 16,
                fontWeight: '600'
              }}>
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileCard;
