import { FC, useLayoutEffect, useRef, useState } from 'react';
import FindrText from '../../../../assets/images/FindrTextGold.png';
import ProfileGrad from '../../../../assets/images/profileGold.png';
import Settings from '../../../../assets/images/gearGold.png';
import FavouriteIcon from '../../../../assets/images/starGold.png';
import { BottomBanner } from '../components.styled';
import {
  BodyMapContainer,
  BodyMapImg,
  BodyMapVideo,
  FooterIcon,
  FooterIconContainer,
  FooterText,
  SelectPrompt,
  TextContainer
} from './bodyMap.styled';
import BodyMapVid from '../../../../assets/images/walkSara.mp4';
import { HeaderLogoContainer } from '../../Home.styled';
import PulsePrompt from '../../../../components/pulsePrompt';
import { isMobileOnly, isTablet } from 'react-device-detect';

interface Props {
  bodyClicked: (bodyPart: string) => void;
  toProfile: () => void;
  userId: string;
}

const BodyMap: FC<Props> = ({ bodyClicked, toProfile, userId }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  // const [playVideo, setPlayVideo] = useState(false);
  // const onClick = () => setPlayVideo(true);

  // useLayoutEffect(() => {
  //   videoRef?.current?.play();
  // }, [playVideo]);

  return (
    <BodyMapContainer>
      <div style={{ position: 'absolute', top: 5, left: 10 }}>
        <FooterIcon mainIcon src={ProfileGrad} alt='profile' />
      </div>
      <div style={{ position: 'absolute', top: 3, left: 8 }}>
        <PulsePrompt title='' size='big' action={toProfile} />
      </div>
      <HeaderLogoContainer src={FindrText} />
      {/* <BodyMapImg src={BodyMapGIF} alt='bodyMap' /> */}
      <div
        style={{
          height: '88%',
          marginLeft: isMobileOnly && !isTablet ? 0 : 30,
          position: 'relative'
        }}>
        <BodyMapVideo
          ref={videoRef}
          src={BodyMapVid}
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: '100%',
            objectFit: 'cover'
          }}
        />
        <div
          style={{
            position: 'absolute',
            bottom: '26%',
            right: isMobileOnly ? '39.5%' : '44%',
            zIndex: 40
          }}>
          <PulsePrompt
            title=''
            size='big'
            action={() => {
              bodyClicked('KNEE');
            }}
          />
        </div>
      </div>

      <div
        style={{
          height: 240,
          width: 200,
          position: 'absolute',
          top: 60
        }}
        onClick={() => {
          console.log('not now prompt');
        }}
      />
      {/* <SelectPrompt
        onClick={() => bodyClicked('Knee')}
        animate={{ scale: 1.4 }}
        transition={{
          duration: 1.8,
          bounce: 0.25,
          repeat: Infinity,
          repeatType: 'reverse'
        }}
      /> */}
      <div
        style={{
          position: 'absolute',
          bottom: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1000
        }}>
        <text style={{ color: '#fff', fontSize: 12 }}>
          select your area of concern
        </text>
      </div>
    </BodyMapContainer>
  );
};

export default BodyMap;
