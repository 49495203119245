import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { auth } from '../firebaseApp';

import {
  addUidToDatabase,
  getCount,
  updateCodeInfo
} from '../services/firebase';

export const generateCode = () => {
  const longCode = Math.random().toString(36).slice(2);
  return longCode.slice(0, 6);
};

export const newLogin = async (info: string, code: string, phone: string) => {
  try {
    const loggedIn = await signInWithEmailAndPassword(auth, info, 'password');
    console.log(loggedIn, 'loggedIn');
    if (loggedIn) {
      const user = loggedIn.user;
      return user.uid;
      //setUserInfo(user.uid);
    }
  } catch ({ message }) {
    if (
      message === 'Firebase: Error (auth/user-not-found).' ||
      message === 'Firebase: Error (auth/invalid-email).'
    ) {
      const numb = await getCount();
      createUserWithEmailAndPassword(
        auth,
        info.length > 4 ? info : `tryFinder${numb}@demo.com`,
        'password'
      )
        .then(async (userCredential) => {
          const user = userCredential.user;
          await addUidToDatabase(
            user.uid,
            info.length > 4 ? info : `tryFinder${numb}@demo.com`,
            phone
          );
          //setUserInfo(user.uid);
          updateCodeInfo(
            code || '',
            info.length > 4 ? info : `tryFinder${numb}@demo.com`,
            phone
          );
          return user.uid;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
