import { motion } from "framer-motion"
import styled from "styled-components"
import { colors } from "../../../../theme"

const BodyMapContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    flex: 1;
`


const SelectPrompt = styled(motion.div)`
    position: absolute;
    bottom: 220px;
    right: 140px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #D5AD5730;
    border: solid 1px #F4D28F;
    cursor: pointer;
    z-index: 1;

    &:hover {
        opacity: 0.8;
    }
`

const BodyMapImg = styled.img`
    height: 75%;
    object-fit: contain;
`

const BodyMapVideo = styled.video`
    height: 75%;
    object-fit: contain;
`

const TextContainer = styled.div`
    font-size: 12px;
    color: ${colors.gray};
    margin-top: 2px;
`

const FooterIconContainer = styled.div<{mainIcon?: boolean}>`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ mainIcon }) => mainIcon && 'margin-top: -14px'};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

const FooterText = styled.div`
    font-size: 10px;
    font-weight: bold;
    color: ${colors.white};
`

const FooterIcon = styled.img<{mainIcon?: boolean}>`
    width: ${({ mainIcon }) => mainIcon ? '40px' : '30px'};
    height: ${({ mainIcon }) => mainIcon ? '40px' : '30px'};
    ${({ mainIcon }) => !mainIcon && 'margin-bottom: 4px'};
`

export { 
    SelectPrompt,
    BodyMapContainer,
    BodyMapImg,
    TextContainer,
    FooterIconContainer,
    FooterText,
    FooterIcon,
    BodyMapVideo
}