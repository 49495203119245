const ProdConfig = {
  // FIREBASE: {
  //   apiKey: 'AIzaSyD3yvXCY7gd_nT09FR9Y-EuGr2vpEs_Uj4',
  //   authDomain: 'findr-1bda2.firebaseapp.com',
  //   projectId: 'findr-1bda2',
  //   databaseURL: 'https://findr-1bda2-default-rtdb.firebaseio.com/',
  //   storageBucket: 'findr-1bda2.appspot.com',
  //   messagingSenderId: '832694831187',
  //   appId: '1:832694831187:web:f9e8163af4ca11c1189ea6',
  //   measurementId: 'G-7R263JYWYJ'
  // }
  FIREBASE: {
    apiKey: 'AIzaSyCjttP-Elow9wH8WqUh8FMd6biPoJ9eg5o',
    authDomain: 'findr-7c563.firebaseapp.com',
    projectId: 'findr-7c563',
    databaseURL: 'https://findr-7c563-default-rtdb.firebaseio.com/',
    storageBucket: 'findr-7c563.appspot.com',
    messagingSenderId: '642296323076',
    appId: '1:642296323076:web:d820090cb80d88c5a4e951',
    measurementId: 'G-7BYWFB26LK'
  }
};

export default ProdConfig;
