import { motion } from 'framer-motion';
import React, { FC } from 'react';

interface Props {
  title: string;
  square?: boolean;
  size: 'big' | 'medium' | 'small';
  action: () => void;
}
const PulsePrompt: FC<Props> = ({ title, size, action, square, children }) => {
  const renderSize = () => {
    switch (size) {
      case 'small': {
        return 25;
      }
      case 'medium': {
        return 30;
      }
      case 'big': {
        return 40;
      }
      default:
        return 80;
    }
  };

  return (
    <>
      <motion.div
        initial={{ scale: 1, opacity: 1 }}
        animate={{ scale: 1.5, opacity: 0 }}
        transition={{ duration: 2, repeat: Infinity }}
        style={{
          height: renderSize(),
          width: renderSize(),
          borderRadius: renderSize() / 2,
          border: '2px solid #d4af37',
          position: 'absolute'
        }}
        onClick={action}
      />
      <motion.div
        style={{
          height: renderSize(),
          width: renderSize(),
          borderRadius: renderSize() / 2,
          border: '2px solid #d4af37',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <text style={{ color: '#d4af37', fontWeight: 'bold' }}>{title}</text>
        {children}
      </motion.div>
    </>
  );
};

export default PulsePrompt;
