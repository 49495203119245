import { motion } from "framer-motion"
import styled from "styled-components"
import { colors } from "../../theme"

const RowHeader = styled(motion.button)<{active: boolean}>`
    height: 40px;
    font-size: 16px;
    width: 100%;
    background-color: ${colors.gray};
    display: flex;
    align-items: center;
    border: none;
    color: ${({ active }) => active ? colors.black : colors.white};
    border-left: solid 1px ${colors.gold};
    border-radius: 8px;
    background: linear-gradient(to bottom, #233556, #00000000);

    ${({ active }) => active && `
        box-shadow: inset 0px 1px 0px ${colors.white},0px 1px 3px rgba(0,0,0,0.3);
        outline: none;
        border: 1px solid #ba6;
        background: linear-gradient(top, #a95, #f2f2f2 25%, #ffffff 38%, #c5c5c5 63%, #f7f7f7 87%, #a95);
        background: -webkit-linear-gradient(top, #a95, #fea 25%, #ffffff 38%, #dc8  63%, #fea 87%, #a95);
        `}
`

const OptionBody = styled(motion.div)`
    background-color: ${colors.blue1};
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: solid 1px ${colors.gold};
    display: flex;
    flex-direction: column;
`

const Option = styled.button<{active: boolean}>`
    margin-bottom: 4px;
    background-color: ${({ active }) =>  active ? colors.halfGray : colors.blue2};
    text-align: left;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: ${colors.white};
    font-size: 16px;
`

const OptionInput = styled.input`
    width: 80%;
    height: 30px;
    background: none;
    border: solid 1px ${colors.whiteSmoke};
    padding: 2px 8px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px ${colors.fadedLemon};
    outline: none;
    color: ${colors.white};
    cursor: pointer;
`
export { RowHeader, OptionBody, Option, OptionInput }