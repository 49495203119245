export enum Stages {
  LOGIN = 'login',
  FAVORITES = 'favorites',
  PROFILE = 'profile',
  SETTINGS = 'settings',
  BODY_MAP = 'bodymap',
  PAIN_LEVEL = 'pain',
  VIDEO = 'video',
  CAT = 'categories',
  FILTER = 'filter',
  UNLOCK = 'unlock',
  LIST = 'list',
  DETAIL = 'detail',
  COMING = 'coming soon'
}

export type ProfileInfo = {
  name: string;
  avatar: string;
};
