import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import Bird from '../../assets/images/FindrBird.png';
import KneeOne from '../../assets/images/tileOne.jpeg';
import KneeTwo from '../../assets/images/KneeOneV2.jpg';
import KneeThree from '../../assets/images/kneeTwoV2.jpg';
import KneeFour from '../../assets/images/kneeFour.jpeg';
import PulsePrompt from '../pulsePrompt';

interface SliderProps {
  toVideo: () => void;
}
const Slider: FC<SliderProps> = ({ toVideo }) => {
  const [numb, setNumb] = useState<number | undefined>(undefined);
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const [clickPos, setClickPos] = useState<number>(0);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [local, setLocal] = useState<number>(222);

  useEffect(() => {
    if (numb) {
      if (numb - clickPos < 140) {
        setSelected(0);
      } else if (numb - clickPos >= 140 && numb - clickPos < 230) {
        setSelected(1);
      } else if (numb - clickPos >= 230 && numb - clickPos < 310) {
        setSelected(2);
      } else {
        setSelected(3);
      }
    }
  }, [numb]);

  return (
    <div
      style={{
        height: 180,
        width: 295,
        marginBottom: 6,
        position: 'relative'
      }}>
      <div style={{ width: '100%', marginTop: 4 }}>
        <motion.div
          // drag={isChrome ? 'x' : false}
          // dragConstraints={{ left: 0, right: 225 }}
          // dragElastic={0}
          // onDrag={(e, { point }) => {
          //   isChrome && setNumb(Math.round(point.x));
          // }}
          animate={{ x: local }}
          transition={{
            duration: 1.2,
            repeat: firstTime ? 1 : 0,
            repeatType: 'reverse'
          }}
          onAnimationComplete={() => {
            console.log(selected);
            setFirstTime(false);
            if (selected === undefined) {
              //setSelected(3);
            }
            if (selected === 3) {
              toVideo();
            }
          }}
          dragMomentum={false}>
          <div
            onMouseDown={(event) => {
              const offset = 35 - event.nativeEvent.offsetX;
              setClickPos(offset > 0 ? -offset : offset);
            }}
            style={{
              backgroundImage: `url(${Bird})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 70,
              height: 40
            }}
          />
        </motion.div>
      </div>
      <div
        style={{
          marginTop: 8,
          background: 'linear-gradient(to right, #989473, #D02929)',
          height: 10,
          width: '100%',
          borderRadius: 10
        }}
      />
      <div
        style={{
          flexDirection: 'row',
          height: 50,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 80
          }}>
          <div
            style={{
              minHeight: 20,
              width: 8,
              marginBottom: 2,
              background: 'linear-gradient(to bottom, #EEEEEE10, #D8D8D8'
            }}
          />
          <div
            style={{
              width: 50,
              minHeight: 50,
              border: selected === 0 ? '1px solid #E5AF19' : 'none',
              borderRadius: 8,
              backgroundImage: `url(${KneeOne})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
            onClick={() => {
              setLocal(0);
              setSelected(0);
            }}
          />
          <div style={{ width: 50, height: 10 }}>
            <text
              style={{
                fontSize: 10,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
                marginTop: 4
              }}>
              Injectable Steroids
            </text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 80
          }}>
          <div
            style={{
              minHeight: 20,
              width: 8,
              marginBottom: 2,
              background: 'linear-gradient(to bottom, #EEEEEE10, #D8D8D8'
            }}
          />
          <div
            style={{
              width: 50,
              minHeight: 50,
              border:
                selected === 1 ? '1px solid #E5AF19' : '1px solid transparent',
              borderRadius: 8,
              backgroundImage: `url(${KneeTwo})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
            onClick={() => {
              setLocal(74);
              setSelected(1);
            }}
          />
          <div style={{ width: 50, height: 10 }}>
            <text
              style={{
                fontSize: 10,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
                marginTop: 4
              }}>
              Arthroscopic Surgery
            </text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 80
          }}>
          <div
            style={{
              minHeight: 20,
              width: 8,
              marginBottom: 2,
              background: 'linear-gradient(to bottom, #EEEEEE10, #D8D8D8'
            }}
          />
          <div
            style={{
              width: 50,
              minHeight: 50,
              border:
                selected === 2 ? '1px solid #E5AF19' : '1px solid transparent',
              borderRadius: 8,
              backgroundImage: `url(${KneeThree})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
            onClick={() => {
              setLocal(150);
              setSelected(2);
            }}
          />
          <div style={{ width: 50, height: 10 }}>
            <text
              style={{
                fontSize: 10,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
                marginTop: 4
              }}>
              Partial Knee
            </text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 80,
            marginBottom: 16
          }}>
          <div
            style={{
              minHeight: 20,
              width: 8,
              marginBottom: 2,
              background: 'linear-gradient(to bottom, #EEEEEE10, #D8D8D8'
            }}
          />

          <div
            style={{
              width: 50,
              minHeight: 50,
              border:
                selected === 3 ? '1px solid #E5AF19' : '1px solid transparent',
              borderRadius: 8,
              backgroundImage: `url(${KneeFour})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'relative'
            }}
            onClick={() => {
              setLocal(222);
              setSelected(3);
              if (selected === 3) {
                toVideo();
              }
            }}>
            <motion.div
              onClick={() => {
                setLocal(222);
                setSelected(3);
                console.log(selected);
                console.log(local);
                if (selected === 3) {
                  toVideo();
                }
              }}
              initial={{ scale: 1, opacity: 1 }}
              animate={{ scale: 1.5, opacity: 0 }}
              transition={{ duration: 2, repeat: Infinity }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 8,
                border: '2px solid #d4af37',
                position: 'absolute',
                bottom: '-4%',
                right: '-3%'
              }}
            />
          </div>
          <div style={{ width: 50, height: 10 }}>
            <text
              style={{
                fontSize: 10,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
                marginTop: 4
              }}>
              Total Knee
            </text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
