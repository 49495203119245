import React, { FC, useEffect, useState } from 'react';
import { BackButtonContainer, BackButtonWithBack } from '../components.styled';
import BackButton from '../../../../assets/images/backButton.png';
import InfoRow from '../../../../components/infoRow';
import Map from '../../../../assets/images/map.png';
import location from '../../../../assets/images/location.png';
import email from '../../../../assets/images/email.png';
import phone from '../../../../assets/images/phone.png';
import { Doctor, Doctors, FirstDoctor } from '../../../../library/staticData';
import { GoldButton } from '../../Home.styled';

interface Props {
  profile: number;
  backClicked: () => void;
  nextClicked: () => void;
  userId: string;
  details: any[];
}
const Details: FC<Props> = ({
  profile,
  nextClicked,
  backClicked,
  userId,
  details
}) => {
  const [survey, setSurvey] = useState<boolean>(false);

  console.log(details);
  console.log('ON DETAILS');

  const [doctorPick, setDoctorPick] = useState<Doctor>();

  useEffect(() => {
    if (profile === -1) {
      setDoctorPick(FirstDoctor);
    } else {
      setDoctorPick(Doctors[profile]);
    }
  }, []);

  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      {!survey ? (
        <>
          <BackButtonContainer
            src={BackButton}
            alt='BackButton'
            onClick={backClicked}
          />
          <div
            style={{
              background: `linear-gradient(#00000060, #00000060), url(${doctorPick?.photo}) no-repeat center top `,
              objectFit: 'cover',
              width: '100%',
              height: '50%'
            }}
          />
          <div
            style={{
              width: '80%',
              margin: -20,
              borderRadius: 20,
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold'
            }}>
            <GoldButton rounded small>
              <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                FINDR VERIFIED
              </text>
            </GoldButton>
          </div>
          <div
            style={{
              marginTop: 10,
              height: '50%',
              background: 'linear-gradient(#1F2839, #1F283910)',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 10,
              justifyContent: 'space-evenly'
            }}>
            <text style={{ color: '#fff', marginBottom: 8, marginTop: 16 }}>
              {doctorPick?.name}
            </text>
            {details.map((rowInfo, index) => {
              if (index < 3) {
                return <InfoRow title={rowInfo.title} detail={rowInfo.value} />;
              }
            })}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%'
              }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InfoRow
                  icon={
                    <img src={location} alt='location' width={20} height={25} />
                  }
                  detail={doctorPick?.address || ''}
                />
                <InfoRow
                  icon={
                    <img src={email} alt='location' width={20} height={20} />
                  }
                  detail='demoDoc@orthodoc.com'
                />
                <InfoRow
                  icon={
                    <img src={phone} alt='location' width={20} height={20} />
                  }
                  detail={doctorPick?.phone || ''}
                />
              </div>
              <img
                src={Map}
                width={110}
                height={110}
                style={{ objectFit: 'cover' }}
                alt='map'
              />
            </div>
            <GoldButton
              onClick={() => {
                //window.open('https://forms.gle/fFJ8KyesQRzJ2m9q7', '_blank');
                setSurvey(true);
              }}>
              try <text style={{ fontFamily: 'sans-serif' }}>FIN</text>
              <text style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
                DR VIP
              </text>
            </GoldButton>
          </div>
        </>
      ) : (
        <>
          <BackButtonWithBack
            src={BackButton}
            alt='BackButton'
            onClick={() => {
              setSurvey(false);
            }}
          />
          <iframe
            style={{ border: 'none' }}
            src='https://docs.google.com/forms/d/e/1FAIpQLSfHpHk2PNQ5xx41TBAUvIoUxw6FXextiCdyholowWYw8K13-g/viewform?embedded=true'
            width='100%'
            height='100%'>
            Loading…
          </iframe>
        </>
      )}
    </div>
  );
};

export default Details;
