import React, { useEffect, useState } from 'react';
import { getStats, getUserStats } from '../../services/firebase';
import { CreateInput } from '../CreateCode/CreateCode.styled';
import { GoldButton } from '../Home/Home.styled';
import {
  Container,
  HeaderCell,
  InnerContainer,
  TableTextCell,
  TableNumberCell,
  TableRow,
  HeaderNumberCell
} from './Stats.styled';

const Stats = () => {
  const [statData, setStatData] = useState<any[]>([]);
  const [byAmount, setByAmount] = useState<boolean>(false);
  const [code, setCode] = useState<string | null>(null);
  const [indData, setIndData] = useState<any[]>([]);
  const [indId, setIndId] = useState<string | null>(null);
  useEffect(() => {
    const getInfo = async () => {
      const data = await getStats();
      const sortedData = data.sort((a, b) => {
        if (byAmount) {
          return b.count - a.count;
        } else {
          return a.id - b.id;
        }
      });
      setStatData(sortedData);
    };
    if (!code) {
      getInfo();
    }
  }, [byAmount]);

  const getIndividualStats = async () => {
    if (code) {
      const indInfo = await getUserStats(code);
      setIndId(indInfo.id);
      const sortedData = indInfo.data.sort((a, b) => {
        return b.count - a.count;
      });
      setIndData(sortedData);
    }
  };

  return (
    <Container>
      <InnerContainer>
        <h3 style={{ color: '#fff' }}>Try FINDR Stats</h3>

        <CreateInput
          value={code || ''}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          placeholder='enter an access code'
        />
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
          <GoldButton
            onClick={() => {
              if (code) {
                getIndividualStats();
              }
            }}>
            Search
          </GoldButton>
          <div style={{ width: 20 }} />
          <GoldButton
            onClick={() => {
              setCode(null);
              setIndData([]);
              setIndId(null);
            }}>
            reset
          </GoldButton>
        </div>
        {indId && <h4 style={{ color: '#fff', marginBottom: 16 }}>{indId}</h4>}
        <TableRow>
          <HeaderCell
            onClick={() => {
              setByAmount(false);
            }}>
            <h4>Item Clicked</h4>
          </HeaderCell>
          <HeaderCell>
            <h4>Description</h4>
          </HeaderCell>
          <HeaderNumberCell
            onClick={() => {
              setByAmount(true);
            }}>
            <h4>Count</h4>
          </HeaderNumberCell>
        </TableRow>
        {indData.length > 0
          ? indData.map((stat) => {
              return (
                <TableRow>
                  <TableTextCell>
                    <h5>{stat.item}</h5>
                  </TableTextCell>
                  <TableTextCell>
                    <h5>{stat.description}</h5>
                  </TableTextCell>
                  <TableNumberCell>
                    <h5>{stat.count}</h5>
                  </TableNumberCell>
                </TableRow>
              );
            })
          : statData.map((stat) => {
              return (
                <TableRow>
                  <TableTextCell>
                    <h5>{stat.item}</h5>
                  </TableTextCell>
                  <TableTextCell>
                    <h5>{stat.description}</h5>
                  </TableTextCell>
                  <TableNumberCell>
                    <h5>{stat.count}</h5>
                  </TableNumberCell>
                </TableRow>
              );
            })}
      </InnerContainer>
    </Container>
  );
};

export default Stats;
