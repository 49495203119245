import styled from "styled-components"
import { colors } from "../../theme"

const RowContainer = styled.div`
    width: 96%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
`
const TitleContainer = styled.div`
    width: 75%;
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
`

const RowText = styled.text<{bold: boolean}>`
    font-size: 12px;
    font-weight: ${({ bold }) => bold ? 900 : 500};
    color: ${colors.white}
`

const DetailContainer = styled.div<{ fullWidth?: boolean }>`
    width: 100%;
    display: flex;
    align-items: flex-start;
`

const IconContainer = styled.div`
    margin-right: 8px;
`
export { RowContainer, TitleContainer, RowText, DetailContainer, IconContainer }