import React, { FC, useEffect, useState } from 'react';
import { BackButtonContainer } from '../components.styled';
import BackButton from '../../../../assets/images/backButton.png';
import { Doctor, Doctors, FirstDoctor } from '../../../../library/staticData';
import { HeaderLogoContainer } from '../../Home.styled';
import FindrText from '../../../../assets/images/FindrTextGold.png';
import { TextContainer } from '../bodyMap/bodyMap.styled';
import Bird from '../../../../assets/images/FindrBird.png';

interface Props {
  backClicked: () => void;
  nextClicked: (i: number) => void;
  userId: string;
  docArray: Doctor[];
}
const ResultsList: FC<Props> = ({
  backClicked,
  nextClicked,
  userId,
  docArray
}) => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 50,
        alignItems: 'center',
        overflowY: 'scroll',
        paddingBottom: 80
      }}>
      <BackButtonContainer
        src={BackButton}
        alt='BackButton'
        onClick={backClicked}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 20,
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <HeaderLogoContainer src={FindrText} />
        <TextContainer>
          select your <text style={{ fontWeight: 'bold' }}>FINDR Verified</text>{' '}
          doctor
        </TextContainer>
      </div>
      <div
        style={{
          marginTop: 36,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <div style={{ width: '90%', marginBottom: 16 }}>
          <Card
            cardInfo={FirstDoctor}
            nextClicked={() => {
              nextClicked(-1);
            }}
            rating={4}
          />
        </div>

        {docArray.map((doctor, index) => {
          if (index < 4) {
            return (
              <div style={{ width: '90%', marginBottom: 16 }}>
                <Card
                  cardInfo={doctor}
                  nextClicked={() => {
                    nextClicked(index);
                  }}
                  rating={4 - index}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

interface CardProps {
  cardInfo: Doctor;
  nextClicked: () => void;
  rating: number;
}
const Card: FC<CardProps> = ({ cardInfo, nextClicked, rating }) => {
  let ratingArray: any[] = [];
  const renderRating = () => {
    console.log(rating);
    for (let i = 0; i < rating; i++) {
      if (ratingArray.length < 4) {
        ratingArray.push(Bird);
      }
    }
  };
  renderRating();
  return (
    <div
      style={{
        height: 80,
        width: '100%',
        border: '2px solid #FAF5F5',
        borderRadius: 8,
        boxShadow: '0px 2px 4px #F1EA06',
        backgroundColor: '#202C44',
        display: 'flex',
        alignItems: 'center'
      }}
      onClick={nextClicked}>
      <img
        src={cardInfo.headShot}
        height={80}
        width={80}
        style={{
          objectFit: 'cover',
          objectPosition: 'center'
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
        <text style={{ color: '#fff', marginBottom: 8, fontWeight: 800 }}>
          {cardInfo.name}
        </text>
        <text style={{ color: '#fff', marginBottom: 8, fontSize: 12 }}>
          {cardInfo.address}
        </text>
        {/* <text
          style={{
            color: '#fff',
            fontSize: 14
          }}>{`${cardInfo.rating} star rating`}</text> */}
        <div style={{ flexDirection: 'row' }}>
          {ratingArray.map((star) => {
            return (
              <img
                src={star}
                alt='bird'
                width={40}
                style={{ marginRight: 4 }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ResultsList;
