import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CreateCode from './Screens/CreateCode';
import Home from './Screens/Home';
import Stats from './Screens/Stats';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/createcode' element={<CreateCode />}></Route>
        <Route path='/welcome' element={<Home />}></Route>
        <Route path='/stats' element={<Stats />}></Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
