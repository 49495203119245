import { Container, NonMobileContainer } from './Home.styled';
import { useState } from 'react';
import MockPhone from '../../components/mockPhone';
import { Stages } from '../../types/types';
import BodyPart from './components/bodyPart/bodyPart';
import BodyMap from './components/bodyMap/bodyMap';
import Filters from './components/filters/filter';
import PaymentScreen from './components/payment';
import ResultsList from './components/resultList';
import Details from './components/details';
import Profile from './components/profile';
import Login from './components/login/login';
import Waiting from '../../components/waiting';
import VideoScreen from './components/video/video';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { addStat } from '../../services/firebase';
import { Doctor, Doctors } from '../../library/staticData';

const Home = () => {
  const [user, setUser] = useState<string | undefined>();
  //const [stage, setStage] = useState<Stages>(Stages.LOGIN);
  const [stage, setStage] = useState<Stages>(Stages.LOGIN);
  const [profile, setProfile] = useState<number>(0);
  const [docs, setDocs] = useState<Doctor[]>([]);
  const [filterInputs, setFilterInputs] = useState<any[]>();

  console.log(filterInputs);

  const renderContent = () => {
    switch (stage) {
      case Stages.LOGIN: {
        return (
          <Login
            setUserInfo={(info) => {
              setUser(info);
              addStat('Enter button', info || 'Anonymous');
              setStage(Stages.BODY_MAP);
            }}
            userId={user || ''}
          />
        );
      }
      case Stages.BODY_MAP: {
        return (
          <BodyMap
            userId={user || ''}
            toProfile={() => {
              setStage(Stages.PROFILE);
              addStat('To Profile', user || 'Anonymous');
            }}
            bodyClicked={(part) => {
              setStage(Stages.PAIN_LEVEL);
              addStat('Knee Clicked', user || 'Anonymous');
            }}
          />
        );
      }
      case Stages.PAIN_LEVEL: {
        return (
          <BodyPart
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.BODY_MAP);
            }}
            nextClicked={() => {
              setStage(Stages.FILTER);
              addStat('Go To Filters', user || 'Anonymous');
            }}
            toVideo={() => {
              setStage(Stages.VIDEO);
              addStat('TKR Tile Clicked', user || 'Anonymous');
            }}
          />
        );
      }
      case Stages.VIDEO: {
        return (
          <VideoScreen
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.PAIN_LEVEL);
            }}
          />
        );
      }
      case Stages.FILTER: {
        return (
          <Filters
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.PAIN_LEVEL);
            }}
            nextClicked={(options) => {
              setFilterInputs(options);
              setStage(Stages.UNLOCK);
              let shuffledDocs = Doctors.sort(() => 0.5 - Math.random());
              setDocs(shuffledDocs);
              addStat('Filter screen complete', user || 'Anonymous');
            }}
            clear={() => {
              setFilterInputs([]);
            }}
          />
        );
      }
      case Stages.UNLOCK: {
        return (
          // <PaymentScreen
          //   backClicked={() => {
          //     setStage(Stages.FILTER);
          //   }}
          //   nextClicked={() => {
          //     setStage(Stages.LIST);
          //   }}
          // />
          <Waiting
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.FILTER);
            }}
            nextClicked={() => {
              setStage(Stages.LIST);
            }}
          />
        );
      }
      case Stages.LIST: {
        return (
          <ResultsList
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.FILTER);
            }}
            nextClicked={(numb) => {
              setProfile(numb);
              setStage(Stages.DETAIL);
              addStat(`Dr ${numb + 1} selected`, user || 'Anonymous');
            }}
            docArray={docs}
          />
        );
      }
      case Stages.DETAIL: {
        return (
          <Details
            userId={user || ''}
            profile={profile}
            backClicked={() => {
              setStage(Stages.LIST);
            }}
            nextClicked={() => {}}
            details={filterInputs || []}
          />
        );
      }
      case Stages.PROFILE: {
        return (
          <Profile
            userId={user || ''}
            backClicked={() => {
              setStage(Stages.BODY_MAP);
              addStat('Leave profile.', user || 'Anonymous');
            }}
          />
        );
      }
      case Stages.COMING: {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            COMING SOON
          </div>
        );
      }
      default:
        return null;
    }
  };

  return (
    <Container mobile={isMobileOnly && !isTablet}>
      {isMobileOnly && !isTablet ? (
        <NonMobileContainer>
          <div style={{ marginTop: 40 }} />
          {renderContent()}
        </NonMobileContainer>
      ) : (
        <MockPhone>{renderContent()}</MockPhone>
      )}
    </Container>
  );
};

export default Home;
