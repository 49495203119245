import React, { FC } from 'react';
import { MobileContainer } from '../../Screens/Home/Home.styled';
import { Dot, Speaker, TopNotch } from './mockPhone.styled';

interface Props {
  children: any;
}

const MockPhone: FC<Props> = ({ children }) => {
  return (
    <MobileContainer>
      <TopNotch>
        <Speaker />
        <Dot />
      </TopNotch>
      {children}
    </MobileContainer>
  );
};

export default MockPhone;
